<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7 text-center" style="color: #1a347f">
          Online Consulting
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 mb-6">
        <div class="fn14-weight7 text-center">Select Consultant</div>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-card
          v-for="(item, index) in items"
          :key="index"
          width="auto"
          height="110"
          class="mx-auto mb-6 backgroundblue"
          @click="openDescription(item)"
        >
          <v-row align="center" justify="center" no-gutters class="mx-1">
            <v-col class="mt-4">
              <v-avatar size="70">
                <v-img :src="item.image_profile" class="ma-0 pa-0 ml-1" />
              </v-avatar>
            </v-col>
            <v-col cols="6" class="pt-4">
              <div class="fn16-weight7" style="color: #1a347f">
                {{ item.first_name }} {{ item.last_name }}
              </div>
              <!-- <div class="fn14">{{ item.status }}</div> -->
            </v-col>
            <v-col class="mt-4">
              <v-btn
                class="rounded-lg buttoncolor"
                small
                dark
                width="96"
                height="40"
                @click="goToForm(item)"
              >
                <!-- {{ item.status === "" ? "Select" : "Book" }} -->
                Select
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDescription">
      <v-card class="pa-4 text-center">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-avatar size="74">
              <v-img :src="dialogData.image_profile"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="12">
            <span>{{ dialogData.first_name }} {{ dialogData.last_name }}</span>
          </v-col>
          <v-col cols="12">
            <span>{{ dialogData.description }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      items: [
        {
          image: require("@/assets/profile/no1.png"),
          name: "Dr.Johnna Dekdee",
          available: "Available every Wed-Sat",
          status: "",
        },
        {
          image: require("@/assets/profile/no2.png"),
          name: "Dr.Emma Watson",
          available: "Available every Wed-Sat",
          status: "",
        },
        {
          image: require("@/assets/profile/no3.png"),
          name: "Dr.John Son",
          available: "Available every Wed-Sat",
          status: "",
        },
        {
          image: require("@/assets/profile/no4.png"),
          name: "Dr.Monet Manet",
          available: "Available every Wed-Sat",
          status: "",
        },
        {
          image: require("@/assets/profile/no5.png"),
          name: "Dr.Dato Santa",
          available: "Available every Wed-Sat",
          status: "book",
        },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
      dialogDescription: false,
      dialogData: [],
    };
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
    this.getAllDoctor();
  },
  methods: {
    openDescription(val) {
      this.dialogData = val;
      this.dialogDescription = true;
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/types/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllDoctor() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/doctors`,
        // `http://localhost:8081/doctors`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllDoctor", response.data);
      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      //   //console.log(this.list);
      // }
    },
    goToForm(val) {
      // localStorage.setItem("DoctorData", Encode.encode(val));
      this.$router.push(`EditForm/?id=${val.id}`);
    },
    ViewType(val) {
      localStorage.setItem("Typedata", Encode.encode(val));
      this.$router.push("ViewType");
    },
    async DeleteType(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/types/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlltype();
        }
      });
    },
    goToCreate() {
      this.$router.push("createtype");
    },
  },
};
</script>
<style>
.buttoncolor {
  color: #f6f6f6;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
.backgroundblue {
  background: linear-gradient(268.1deg, #d5e4ff 7.54%, #c1d1ff 100.72%);
}
</style>
